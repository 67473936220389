import {Box, Heading, HStack, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip,} from '@chakra-ui/react'
import {motion} from "framer-motion";
import {useState} from "react";

const ListOfEmails = ({emails}) => {
	return (
		<Stack mt="5">
			{emails.map((email) => (
				<motion.div
					initial={{ opacity: 1, scale: 1 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 1.5 }}
					key={email.id}
				>
					<Box mb={'5px'} bg="bg-surface" py={"15px"}
					     shadow={{
						     sm: 'base',
					     }}
					     rounded={{
						     sm: 'lg',
					     }}
					>
						<Stack fontSize="sm" px="4" spacing="4">
							<Stack direction="row" spacing="4">
								<HStack spacing="3">
									<Box width={"200px"}>
										<Tooltip label={email.attributes.sender_email} fontSize='xs'>
											<Text fontWeight="medium" color="emphasized"
											      sx={{
												      WebkitBoxOrient: 'vertical',
												      WebkitLineClamp: '1',
												      overflow: 'hidden',
												      display: '-webkit-box',
											      }}
											>
												{email.attributes.sender_name}
											</Text>
										</Tooltip>
									</Box>
								</HStack>
								<Box width={"400px"}>
									<Text
										fontWeight="medium"
										sx={{
											WebkitBoxOrient: 'vertical',
											WebkitLineClamp: '1',
											overflow: 'hidden',
											display: '-webkit-box',
										}}
									>
										{email.attributes.subject}
									</Text>
								</Box>
								<Box>
									<Text
										color="muted"
										sx={{
											WebkitBoxOrient: 'vertical',
											WebkitLineClamp: '1',
											overflow: 'hidden',
											display: '-webkit-box',
										}}
									>
										{email.attributes.summary || email.attributes.snippet}
									</Text>
								</Box>
							</Stack>

						</Stack>

					</Box>
				</motion.div>
			))}
		</Stack>
	)
}

export const DesktopEmails = ({emails}) => {
	const [tabIndex, setTabIndex] = useState(0)

	const uniqueNames = [...new Set(emails.map(email => email.attributes.category))];

	const selectEmailsByCategory = (category) => {
		return emails.filter(email => !email.attributes.first_sort_action_applied).filter(email => email.attributes.category === category)
	}

	return (
		<Stack>
			<Tabs onChange={(index) => setTabIndex(index)}>
				<TabList>
					{uniqueNames.map((category) => (
						<Tab key={category}>{category} ({selectEmailsByCategory(category).length})</Tab>
					))}
				</TabList>
				<TabPanels mt={"10px"}>
					{uniqueNames.map((category) => (
						<TabPanel key={category} px={'0'}>
							<Box key={category}>
								<Stack spacing="4" direction={{ base: 'column', md: 'row' }} mb={"10px"} justify="space-between">
									<Stack spacing="1">
										<Heading size='xs'  fontWeight="medium">
											{category}
										</Heading>
										<Text color="muted">{selectEmailsByCategory(category)[0].attributes.category_description}</Text>
									</Stack>
								</Stack>
								<ListOfEmails emails={selectEmailsByCategory(category)}/>
							</Box>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		</Stack>
	)
}

