import {Button, Flex, HStack, Icon, Link, Stack, Text, useColorModeValue as mode,} from '@chakra-ui/react'
import {FiArchive, FiArrowDownLeft, FiArrowUpRight, FiBookOpen, FiX,} from 'react-icons/fi'
import {ColumnHeader, ColumnIconButton} from './Column'
import {Logo} from "../../../components/Logo";
import {NavLink as RouterNavLink, useParams} from 'react-router-dom';

export const InboxNavBar = ({dataToUse, ...props}) => {
	const { filter, subfilter } = useParams();

	const keepInInbox = dataToUse.filter((email) => email.attributes.recommendation === 'keep_in_inbox' && email.attributes.category !== 'Uncategorized')
	const uncategorized = dataToUse.filter((email) => email.attributes.category === 'Uncategorized')

	const eventuallyArchive = dataToUse.filter((email) => email.attributes.recommendation === 'eventually_archive_and_label')
	const archive = dataToUse.filter((email) => email.attributes.recommendation === 'archive_and_label')

	const archivedCategories = [...new Set(dataToUse.map(email => email.attributes.category))];

	const selectEmailsByCategory = (category) => {
		return archive.filter(email => email.attributes.category === category)
	}

	return(<Flex as="nav" height="full" direction="column" justify="space-between" {...props}>
		<Stack spacing="3">
			<ColumnHeader>
				<HStack spacing="3">
					<ColumnIconButton
						onClick={props.onClose}
						aria-label="Close navigation"
						icon={<FiX/>}
						display={{
							base: 'display',
							lg: 'none',
						}}
					/>
				</HStack>
				<HStack>
					<Logo width={"80%"} ml={"0px"} />
				</HStack>

			</ColumnHeader>

			<Stack px="3" spacing="6">
				<Stack spacing="3">
					<NavHeading>Your Inbox</NavHeading>
					<Stack spacing="1">
						<ChakraNavLink icon={FiBookOpen} to="/user-dashboard/inbox/active/all">
							Kept in Inbox ({keepInInbox.length})
						</ChakraNavLink>
						<ChakraNavLink icon={FiBookOpen} to="/user-dashboard/inbox/active/uncategorized">
							Uncategorized ({uncategorized.length})
						</ChakraNavLink>
						{/*<ChakraNavLink icon={FiBookOpen} to="/user-dashboard/inbox/active/needs_review">*/}
						{/*	Needs Review ({keepInInbox.length})*/}
						{/*</ChakraNavLink>*/}
						{/*<ChakraNavLink icon={FiBookOpen} to="/user-dashboard/inbox/active/reply_to">*/}
						{/*	Reply to ({keepInInbox.length})*/}
						{/*</ChakraNavLink>*/}
						{/*<ChakraNavLink icon={FiBookOpen} to="/user-dashboard/inbox/active/to_do">*/}
						{/*	To do ({keepInInbox.length})*/}
						{/*</ChakraNavLink>*/}
						{/*<ChakraNavLink icon={FiBookOpen} to="/user-dashboard/inbox/active/moving_to_archive_soon">*/}
						{/*	Storing soon ({keepInInbox.length})*/}
						{/*</ChakraNavLink>*/}
					</Stack>

				</Stack>
				<Stack spacing="3">
					<NavHeading>Storage</NavHeading>
					<Stack spacing="1">
						{archivedCategories.map((category) => (
							selectEmailsByCategory(category).length > 0 &&
							<ChakraNavLink key={`inboxnav${category}`} icon={FiArchive} to={`/user-dashboard/inbox/storage/${category}`}>
								{category} ({selectEmailsByCategory(category).length})
							</ChakraNavLink>
						))}
					</Stack>
				</Stack>
				<Stack spacing="3">
					<NavHeading>Settings</NavHeading>
					<Stack spacing="1">
						<ChakraNavLink icon={FiArrowDownLeft} to={`/user-onboarding/`}>
							Leave inbox view
						</ChakraNavLink>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	</Flex>
	)
}

const NavButton = (props) => (
	<Button
		width="full"
		borderRadius="0"
		variant="ghost"
		size="lg"
		fontSize="sm"
		_hover={{
			bg: mode('gray.100', 'gray.700'),
		}}
		_active={{
			bg: mode('gray.200', 'gray.600'),
		}}
		_focus={{
			boxShadow: 'none',
		}}
		_focusVisible={{
			boxShadow: 'outline',
		}}
		{...props}
	/>
)



const ChakraNavLink = (props) => {
	const {icon, ...linkProps} = props
	return(
		<Link
			as={RouterNavLink}
			px="2"
			py="1.5"
			borderRadius="md"
			_hover={{
				bg: mode('gray.100', 'gray.700'),
			}}
			_activeLink={{
				bg: 'gray.700',
				color: 'white',
			}}
			{...linkProps}
		>
			<HStack justify="space-between">
				<HStack spacing="3">
					<Icon as={icon} />
					<Text as="span" fontSize="sm" lineHeight="1.25rem">
						{props.children}
					</Text>
				</HStack>
				{props.isExternal && (
					<Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
				)}
			</HStack>
		</Link>
	)
}

export const NavHeading = (props) => (
	<Text
		as="h4"
		fontSize="xs"
		fontWeight="semibold"
		px="2"
		lineHeight="1.25"
		color={mode('gray.600', 'gray.400')}
		{...props}
	/>
)