import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const TextEditor = (props) => {
	const {initialValue, value, setValue, ...otherProps} = props

	const setValueAfterCleaning = (value) => {
		if (value === "<p><br></p>") {
			setValue("")
			return
		} else {
			setValue(value)
		}
	}
	return <ReactQuill theme="snow" value={value} onChange={setValueAfterCleaning} />;
}