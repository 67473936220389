import {Box, Button, Container, Divider, Stack, Text,} from '@chakra-ui/react'
import {Logo} from '../Logo'
import {Link} from "react-router-dom";

export const Footer = () => (
	<Box bg="bg-accent" color="on-accent">
		<Container as="footer" role="contentinfo" maxW="100%">
			<Stack
				spacing="8"
				direction={{ base: 'column', md: 'row' }}
				justify="space-between"
				py={{ base: '12', md: '16' }}
			>
				<Stack spacing={{ base: '6', md: '8' }} align="start">
					<Logo logoColor={"white"}/>
					<Text color="on-accent-muted">AI-assisted Email Management</Text>
				</Stack>
				<Stack
					direction={{ base: 'column-reverse', md: 'column', lg: 'row' }}
					spacing={{ base: '12', md: '8' }}
				>
					<Stack direction="row" spacing="8">
						<Stack spacing="4" minW="36" flex="1">
							<Text fontSize="sm" fontWeight="semibold" color="on-accent-subtle">
								Product
							</Text>
							<Stack spacing="3" shouldWrapChildren>
								<Link to='/how-it-works'>
									<Button variant="link-on-accent">How It Works</Button>
								</Link>
							</Stack>
							<Stack spacing="3" shouldWrapChildren>
								<Link to='/terms-of-service'>
									<Button variant="link-on-accent">Terms</Button>
								</Link>
							</Stack>
						</Stack>
						<Stack spacing="4" minW="36" flex="1">
							<Text fontSize="sm" fontWeight="semibold" color="on-accent-subtle">
								Privacy & Data
							</Text>
							<Stack spacing="3" shouldWrapChildren>
								<Link to='/privacy-policy'>
									<Button variant="link-on-accent">Privacy</Button>
								</Link>
								<Link to='/data-disclosure'>
									<Button variant="link-on-accent">Data Disclosure</Button>
								</Link>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Divider borderColor="bg-accent-subtle" />
			<Stack
				pt="8"
				pb="12"
				justify="space-between"
				direction={{ base: 'column-reverse', md: 'row' }}
				align="center"
			>
				<Text fontSize="sm" color="on-accent-subtle">
					&copy; {new Date().getFullYear()} Adventurous AI LLC. All rights reserved.
				</Text>
			</Stack>
		</Container>
	</Box>
)