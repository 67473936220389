import {
	Box,
	Container,
	Divider,
	Heading,
	Stack,
	Table,
	TableCaption,
	TableContainer,
	Text,
	Thead, Tr, Th, Td, Tbody, Link
} from '@chakra-ui/react'
import React from "react";
import ScrollToTop from "../../../components/ScrollToTop";
import {ExternalLinkIcon} from "@chakra-ui/icons";

const Header = () => {
	return(
		<Box pt="8" pb="16">
			<Stack spacing={{ base: '8', md: '10' }} align="center">
				<Stack spacing={{ base: '4', md: '6' }} textAlign="center">
					<Stack spacing="4">
						<Heading size={{ base: 'm'}}>Disclosure of Data Usage</Heading>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	)
}

const ActualContent = () => {
	return(
		<Box maxW="5xl" mx="auto">

			<Text fontsize={"m"} mt={"15px"}>
				AutoInbox is in process of becoming a verified Google Workspace app. It means AutoInbox is completing a validation process before being published on Google's Marketplace. Read more about Google's verification process <Link color='teal.500' href={"https://support.google.com/cloud/answer/9110914"}>here</Link>.
			</Text>
			<br/>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				Permissions AutoInbox uses
			</Heading>

			<Text fontSize={"m"} mt={"15px"}>
				AutoInbox exists to simplify and organize your email, and requires several permissions to do so effectively.
				Below is a breakdown of each permission granted and what it's used for:
				<br/><br/>
				<TableContainer>
					<Table variant='simple'>
						<Thead>
							<Tr>
								<Th>Permissions</Th>
								<Th>Why does AutoInbox require this permission?</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Tr>
								<Td>View your e-mails</Td>
								<Td>AutoInbox’s core function of categorizing and summarizing your e-mails are done<br/>
									by processing your e-mail data with an AI bot. </Td>
							</Tr>
							<Tr>
								<Td>Compose, edit, and send e-mails</Td>
								<Td>AutoInbox lets you compose and preview drafts of e-mails in our web app,<br/>
									and gives you the option of having AI write replies for you. </Td>
							</Tr>
							<Tr>
								<Td>Insert e-mails into your inbox</Td>
								<Td>AutoInbox tracks the history of changes we make to your inbox and sends<br/>
									a summary to you directly, with options to undo or edit the action(s). </Td>
							</Tr>
							<Tr>
								<Td>Add, edit and delete your labels</Td>
								<Td>AutoInbox core function after categorizing your e-mail is to help manage your inbox<br/>
									by editing or applying labels to individual e-mails, such as archiving it, or making<br/>
									sure it stays in your inbox.</Td>
							</Tr>
							<Tr>
								<Td>Allow this application to run when you are not present</Td>
								<Td>AutoInbox is constantly working to manage your e-mail, and functions best when you<br/>
									don’t have to manage it yourself. This permission lets us manage your e-mail on<br/>
									an ongoing basis, so everything is up to date when you login.</Td>
							</Tr>
						</Tbody>
					</Table>
				</TableContainer>
			</Text>

			<br/>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				E-mail Data AutoInbox stores and how it’s used
			</Heading>
			<Text fontSize={"m"} mt={"15px"}>
				AutoInbox stores your e-mails and related content (e-mail content, subject, to, from, and other metadata) to provide the service, as detailed above.
				<br/><br/> This data is also processed by our integration with OpenAI to provide our service; you can read more about their API data usage policies at <Link color='teal.500' href={"https://openai.com/policies/api-data-usage-policies"} isExternal>https://openai.com/policies/api-data-usage-policies</Link>.
				<br/><br/>The use of information received from Gmail APIs will adhere to the
				<Link color='teal.500' href={"https://developers.google.com/terms/api-services-user-data-policy"} isExternal> Google User Data Policy</Link>,
				including the <Link color='teal.500' href={"https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"} isExternal>Limited Use</Link> requirements.
			</Text>
			<br/>

			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				AutoInbox privacy policy
			</Heading>
			<Text fontSize={"m"} mt={"15px"}>
				To view our full privacy policy, visit <Link color={"teal.500"} href={"https://app.autoinbox.ai/privacy-policy"}>https://app.autoinbox.ai/privacy-policy</Link>
			</Text>
			<br/>

			{/*Kept this in just in case we want to bring it back later, or need to.*/}
			{/*<Heading size={"sm"} mt={"15px"} mb={"15px"}>*/}
			{/*	Third-party subprocesses AutoInbox engages*/}
			{/*</Heading>*/}
			{/*<Text fontSize={"m"} mt={"15px"}>*/}
			{/*	AutoInbox utilizes the following third-parties to provide you with our service:*/}
			{/*	<TableContainer>*/}
			{/*		<Table variant='simple'>*/}
			{/*			<Thead>*/}
			{/*				<Tr>*/}
			{/*					<Th>Entity</Th>*/}
			{/*					<Th>Activity</Th>*/}
			{/*				</Tr>*/}
			{/*			</Thead>*/}
			{/*			<Tbody>*/}
			{/*				<Tr>*/}
			{/*					<Td>Stripe</Td>*/}
			{/*					<Td>Payments processing</Td>*/}
			{/*				</Tr>*/}
			{/*				<Tr>*/}
			{/*					<Td>Google Cloud</Td>*/}
			{/*					<Td>Application hosting, cloud computing and storage facility</Td>*/}
			{/*				</Tr>*/}
			{/*				<Tr>*/}
			{/*					<Td>Google Workspace</Td>*/}
			{/*					<Td>Customer support and internal productivity suite</Td>*/}
			{/*				</Tr>*/}
			{/*				<Tr>*/}
			{/*					<Td>OpenAI</Td>*/}
			{/*					<Td>Language Model API</Td>*/}
			{/*				</Tr>*/}
			{/*			</Tbody>*/}
			{/*		</Table>*/}
			{/*	</TableContainer>*/}
			{/*</Text>*/}

		</Box>
	)
}

function DataDisclosure() {

	return (
		<Container py={{ base: '16', md: '24' }}>
			<ScrollToTop />
			<Stack spacing="10" shouldWrapChildren>
				<Header />
				<ActualContent />
			</Stack>
		</Container>
);
}

export default DataDisclosure;
