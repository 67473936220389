import {Box, Container, Heading, Link, Stack, Text} from '@chakra-ui/react'
import {Logo} from "../../../components/Logo";
import LoginWithGoogle from "../../../components/LoginWithGoogle";

export const LoginPage = () => (
	<Box bgGradient={{ sm: 'linear(to-r, pink.600, purple.600)' }} py={{ base: '12', md: '24' }} minHeight="100vh" >
		<Container
			maxW="md"
			py={{ base: '0', sm: '8' }}
			px={{ base: '4', sm: '10' }}
			bg={{ base: 'transparent', sm: 'bg-surface' }}
			boxShadow={{ base: 'none', sm: 'xl' }}
			borderRadius={{ base: 'none', sm: 'xl' }}
		>
			<Stack spacing="8">
				<Stack spacing="6" align="center">
					<Link href={"/"}>
						<Logo />
					</Link>
					<Stack spacing="3" textAlign="center">
						<Heading size="xs">Connect to your account</Heading>
					</Stack>
				</Stack>
				<Stack spacing="6">
					<LoginWithGoogle />
				</Stack>
				<Text fontSize="xs" color="subtle" textAlign="center">AutoInbox needs to connect to your Google account to send emails on your behalf and manage your emails to provide a stellar experience</Text>
				<Text fontSize="xs" color="subtle" textAlign="center" mt={"20px"}>
					Learn about <Link color='teal.500' href={"/privacy-policy"} isExternal>how we manage privacy</Link> and <Link color='teal.500' href={"/data-disclosure"} isExternal> how we use your data</Link> here.
				</Text>
			</Stack>
		</Container>
	</Box>
)