import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
	baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api`,
	// prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
	prepareHeaders: (headers, { getState }) => {
		const token = getState().authReducer.userToken
		if (token) {
			headers.set('authorization', `Bearer ${token}`)
			return headers
		}
	},
})

export const autoInboxApi = createApi({
	reducerPath: 'autoInboxApi',
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getUserProfile: builder.query({
			query: () => `/user/`,
		}),
		getUserOnboardingEmails: builder.query({
			query: () => `/user_onboarding/get_initial_batch_of_emails`,
			providesTags: ['UserOnboardingEmails'],
		}),
		joinWaitlist: builder.mutation({
			query: (params) => ({
				url: `/user_onboarding/join_waitlist`,
				method: 'POST',
				body: params,
			}),
		}),
		applySortActionToOnboardingEmails: builder.mutation({
			query: (updatedData) => ({
				url: `/user_onboarding/apply_sort_action_to_onboarding_emails`,
				method: 'PUT',
				body: updatedData,
			}),
			invalidatesTags: ['UserOnboardingEmails'],
		}),
		sendReplyToEmail: builder.mutation({
			query: (replyData) => ({
				url: `/user_onboarding/send_reply`,
				method: 'POST',
				body: replyData,
			}),
			invalidatesTags: ['UserOnboardingEmails'],
		}),
	}),
})

export const { useGetUserProfileQuery,
	useGetUserOnboardingEmailsQuery,
	useApplySortActionToOnboardingEmailsMutation,
	useSendReplyToEmailMutation,
	useJoinWaitlistMutation
} = autoInboxApi
