import AnalysisInProgress from "./analysis-in-progress";

const userOnboardingRoutesList = [
	// By default the first path will be the index route
	{
		path: "",
		element: <AnalysisInProgress />,
		title: "Getting Started"
	},
	{
		path: "analysis-in-progress",
		element: <AnalysisInProgress />,
		title: "Getting Started > Analysis In Progress"
	},
];

export default userOnboardingRoutesList;