import {createSlice} from '@reduxjs/toolkit';
// import _ from "underscore";

// const selectRandomValue = (options) => {
// 	return _.sample(options)
// }

const initialState = {
	test: "test"
};


const userSetupReducer = createSlice({
	name: 'userSetupReducer',
	initialState,
	reducers: {
		updateTestValue: (state, action) => {
			state.test = "test1"
		},
	},
});

export const { updateTestValue} = userSetupReducer.actions;
export default userSetupReducer.reducer;