import {Box, Button, Heading, Stack, Text, useColorModeValue} from '@chakra-ui/react'
import {useCallback, useEffect, useRef, useState} from 'react';
import {ReplyDrafter} from "./ReplyDrafter";

const EmailDisplay = ({emailHTML}) => {
	const iframeRef = useRef();

	useEffect(() => {
		const iframeDoc = iframeRef.current.contentWindow.document;
		iframeDoc.open('text/html', 'replace');
		iframeDoc.write(emailHTML);
		iframeDoc.close();
	}, [emailHTML]);

	return <iframe ref={iframeRef} style={{width: '100%', height: '100%'}} />;
};

export default EmailDisplay;


export const Main = ({activeEmail, setActiveEmail, props}) => {
	const [openEditor, setOpenEditorValue] = useState(false);

	useEffect(() => {
		setOpenEditorValue(false)
	}, [activeEmail]);

	const handleKeyPress = useCallback((event) => {
		if (event.key === 'Escape') {
			setActiveEmail(false);
		}
	}, [setActiveEmail]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);

	return(<Box as="main" {...props}>
		<Stack spacing="8">
			<Stack spacing="3">
				<Heading as="h3" size="xs" color={useColorModeValue('gray.700', 'white')}>
					{activeEmail.attributes.subject}
				</Heading>
				<Text
					fontWeight="medium"
					sx={{
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: '1',
						overflow: 'hidden',
						display: '-webkit-box',
					}}
				>
					From: {activeEmail.attributes.sender_name}
				</Text>
				<Text fontSize='sm' color={useColorModeValue('blackAlpha.600', 'whiteAlpha.600')}>October 23, 2021</Text>
			</Stack>
			<Stack
				spacing="5"
				lineHeight="1.75"
				color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
			>
				<Box width={"100%"} height={"40vh"}>
					<EmailDisplay emailHTML={activeEmail.attributes.html_body || activeEmail.attributes.text_body || activeEmail.attributes.text_body_from_parent_part} />
				</Box>

				<Button onClick={() => {setOpenEditorValue(!openEditor)}}>
					{openEditor ? "Cancel Reply" : "Reply"}
				</Button>
				{openEditor && <ReplyDrafter setOpenEditorValue={setOpenEditorValue} activeEmail={activeEmail} key={activeEmail.id} />}
			</Stack>
		</Stack>
	</Box>)
}