import {Box,} from '@chakra-ui/react'
import Hero from "./hero";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React from "react";
import {useGetUserProfileQuery} from "../../../services/autoInboxApi";
import ScrollToTop from "../../../components/ScrollToTop";
import {ProductDetails, ProductDetailsPrivacy} from "./product-details";

function Home() {
	const userToken = useSelector(state => state.authReducer.userToken)
	const { isSuccess, isLoading } = useGetUserProfileQuery(undefined, {
		skip: !userToken, // Skip the query if userToken is not available
	});
	const navigate = useNavigate();

	React.useEffect(() => {
		if (isSuccess) {
			navigate('/user-onboarding/analysis-in-progress');
		}
		if (isLoading) {
			// console.log('Loading', isLoading)
		}
	}, [isSuccess, isLoading]);

	return (
		<Box>
			<ScrollToTop />
			<Hero />
			<ProductDetails />
			<ProductDetailsPrivacy />
		</Box>
);
}

export default Home;
