import {Link, Stack, Text, useColorModeValue as mode} from '@chakra-ui/react'

export const Sidebar = ({activeEmail, setActiveEmail, emailsToDisplay, props}) => (
	<Stack
		spacing={{
			base: '1px',
			lg: '1',
		}}
		px={{
			lg: '3',
		}}
		py="3"
		{...props}
	>
		{emailsToDisplay.map((email) => (
			<Link
				key={email.id}
				aria-current={email.id === activeEmail.id ? 'page' : undefined}
				_hover={{
					textDecoration: 'none',
					bg: mode('gray.100', 'gray.700'),
				}}
				_activeLink={{
					bg: 'gray.700',
					color: 'white',
				}}
				borderRadius={{
					lg: 'lg',
				}}
				onClick={() => setActiveEmail(email)}
			>
				<Stack
					spacing="1"
					py={{
						base: '3',
						lg: '2',
					}}
					px={{
						base: '3.5',
						lg: '3',
					}}
					fontSize="sm"
					lineHeight="1.25rem"
				>
					<Text
						fontWeight="medium"
						sx={{
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: '1',
							overflow: 'hidden',
							display: '-webkit-box',
						}}
					>
						{email.attributes.sender_name}
					</Text>
					<Text
						sx={{
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: '1',
							overflow: 'hidden',
							display: '-webkit-box',
						}}
					>
						{email.attributes.subject}
					</Text>

					<Text
						opacity={0.6}
						sx={{
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: '1',
							overflow: 'hidden',
							display: '-webkit-box',
						}}
					>
						{email.attributes.summary || email.attributes.snippet}
					</Text>
					<Text opacity={0.6}>{email.attributes.date}</Text>
				</Stack>
			</Link>
		))}
	</Stack>
)