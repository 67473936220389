import {Box, Container, Divider, Heading, ListItem, Stack, Text, UnorderedList, Link} from '@chakra-ui/react'
import React from "react";
import ScrollToTop from "../../../components/ScrollToTop";

const Header = () => {
	return(
		<Box pt="8" pb="16">
			<Stack spacing={{ base: '8', md: '10' }} align="center">
				<Stack spacing={{ base: '4', md: '6' }} textAlign="center">
					<Stack spacing="4">
						<Text fontWeight="semibold" color="accent" fontSize={{ base: 'sm', md: 'md' }}>
							Last Updated: May 18, 2023
						</Text>
						<Heading size={{ base: 'lg'}}>Privacy Policy</Heading>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	)
}

const ActualContent = () => {
	return(
		<Box maxW="5xl" mx="auto">
			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				1. Introduction
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				Adventurous AI LLC, the owners and operators of the AutoInbox trademark and intellectual property, AutoInbox data services (“AutoInbox”), provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use, protection, and disclosure of any information received from your use of this website, located at <a href="https://autoinbox.ai">www.autoinbox.ai</a> (“Site”), as well as all related websites including our subdomains, applications, browser extensions, and other services provided by us (collectively, together with the Site, our “Service”), and in connection with our customer, vendor, and partner relationships. This Privacy Policy also tells you about your rights and choices with respect to your Personal Information, and how you can reach us to update your contact information or get answers to questions you may have about our privacy practices.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				By using the Site and registering for our Services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Service, accessible at <a href="https://autoinbox.ai">www.autoinbox.ai</a>.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				This Privacy Policy applies exclusively to customers residing in the United States. We do not offer our services to individuals outside of the United States, and any personal information provided by non-US customers will not be knowingly collected or stored. If you are accessing our services from outside the United States, please refrain from using our services or providing any personal information.			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				2. Personal Information We May Collect
			</Heading>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				2.1. Personal Information Provided By You
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.1.1. Registration.</b> If you desire to have access to certain restricted sections of the Site or request to receive marketing materials, you may be required to become a registered user, and to submit the following types of Personal Information to AutoInbox: your name, company, professional title, phone number, email address, physical address.
			</Text>

			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.1.2. Customer Support.</b> We may collect information through your communications with our customer support team via text messages, email, Facebook, Facebook Messenger, Twitter, Instagram, or any other communications that you may send us and their contents.
			</Text>

			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.1.3. Making a Payment.</b> When you make payments through the Service, you will need to provide Personal Information such as your credit card number and zip code. AutoInbox secures all payment data through our integration with Stripe. You can read more about their terms of service and privacy policies on Stripe’s website at www.stripe.com.
			</Text>

			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.1.4. Other.</b> We may also collect your contact details when you provide them in the context of our customer, vendor, and partner relationships.
			</Text>

			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				2.2. Personal Information automatically obtained from your interactions with the Service
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.2.1. Log Data.</b> When you use our Service, our servers automatically record information that your browser sends whenever you visit a website (“Log Data”). This Log Data may include information such as your IP address, browser type or the domain from which you are visiting, the web pages you visit, the search terms you use, and any advertisements on which you click.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.2.2. Cookies and Similar Technologies.</b> Like many websites, we also use “cookie” technology to collect additional website usage data and to improve the Site and our Service. A cookie is a small data file that we transfer to your computer’s hard disk. A session cookie enables certain features of the Site and our service and is deleted from your computer when you disconnect from or leave the Site. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Site. Persistent cookies can be removed by following your web browser help file directions. Most Internet browsers automatically accept cookies. AutoInbox may use both session cookies and persistent cookies to better understand how you interact with the Site and our Service, to monitor aggregate usage by our users and web traffic routing on the Site, and to improve the Site and our Service.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.2.3. Web Beacons and Similar Technologies.</b> We may also automatically record certain information from your device by using various types of technology, including “clear gifs” or “web beacons.” This automatically collected information may include your IP address or other device address or ID, web browser and/or device type, the web pages or sites that you visit just before or just after you use the Service, the pages or other content you view or otherwise interact with on the Service, and the dates and times that you visit, access, or use the Service. We also may use these technologies to collect information regarding your interaction with email messages, such as whether you opened, clicked on, or forwarded a message, to the extent permitted under applicable law.
			</Text>
			<Text fontSize={"log"} mt={"15px"}>
				You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. Please note that if you delete, or choose not to accept, cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				<b>2.2.4. Do Not Track Requests.</b> AutoInbox does not process or respond to web browsers’ “do not track” signals or other similar transmissions that indicate a request to disable online tracking of users who use our Service. does not process or respond to web browsers’ “do not track” signals or other similar transmissions that indicate a request to disable online tracking of users who use our Service.
			</Text>

			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				2.3. E-mail data automatically obtained in order to provide the Service
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				When you use our Service, we may store your e-mail data in order to provide the Service. This includes the e-mail subject, contents, “to” and “from” fields, timestamps, and other e-mail metadata.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				E-mail data is processed through our integration with OpenAI. You can read more about their API data usage policies at <Link color='teal.500' href="https://openai.com/policies/api-data-usage-policies" isExternal>https://openai.com/policies/api-data-usage-policies</Link>
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				The use of information received from Gmail APIs will adhere to the <Link href={"https://developers.google.com/terms/api-services-user-data-policy"} isExternal color='teal.500'>Google User Data Policy</Link>, including the <Link color='teal.500' href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" isExternal>Limited Use</Link> requirements.
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				3. How we may use your Personal Information
			</Heading>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				3.1. Personal Information Provided By You & Personal Information automatically obtained from your interactions with the Service
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				We may use the Personal Information Provided By You & Personal Information automatically obtained from your interactions with the Service we obtain about you to:
				<UnorderedList>
					<ListItem>create and manage your account, provide our Service, process payments, and respond to your inquiries;</ListItem>
					<ListItem>communicate with you to verify your account and for informational and operational purposes, such as account management, customer service, or system maintenance;</ListItem>
					<ListItem>tailor our Service (e.g., we may use cookies and similar technologies to remember your preferences);</ListItem>
					<ListItem>publish your content, comments or messages on social media platforms;</ListItem>
					<ListItem>provide tailored advertising, for AutoInbox services, via Google AdWords, Facebook Advertising, and/or Reddit Advertising;</ListItem>
					<ListItem>aggregate your Personal Information for analytical purposes;</ListItem>
					<ListItem>provide customer support;</ListItem>
					<ListItem>operate, evaluate and improve our business (including by developing new products and services; managing our communications; determining the effectiveness of our advertising; analyzing how the Service is being accessed and used; tracking performance of the Service; debugging the Service; facilitating the use of our Service);</ListItem>
					<ListItem>send you marketing communications about products, services, offers, programs and promotions of AutoInbox, and affiliated companies;</ListItem>
					<ListItem>ensure the security of our Service;</ListItem>
					<ListItem>manage our customer, service provider and partner relationships;</ListItem>
					<ListItem>enforce our agreements related to our Service and our other legal rights; and</ListItem>
					<ListItem>comply with applicable legal requirements, industry standards and our policies.</ListItem>
				</UnorderedList>
				<br />
				<b>Aggregated or De-Identified Information.</b> We may aggregate or de-identify Personal Information and use the aggregated information to analyze the effectiveness of our Services, to improve and add features to our Services, to conduct research and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our Services and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally available. We may collect aggregated information through the Services, through cookies, and through other means described in this Privacy Policy. We will maintain and use de-identified information in anonymous or de-identified form and we will not attempt to reidentify the information.
			</Text>

			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				3.2. E-mail Data automatically obtained in order to provide the Service
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				We take your personal e-mail data privacy very seriously, and will only use e-mail data collected to:
				<UnorderedList>
					<ListItem>provide customer support;</ListItem>
					<ListItem>operate, evaluate and improve the Service (including tracking performance of the Service; debugging the Service; facilitating the use of our Service);</ListItem>
					<ListItem>comply with applicable legal requirements, industry standards and our policies.</ListItem>
				</UnorderedList>

				<Heading size={"xxs"} mt={"15px"} mb={"15px"}>
					Additional Limits on Use of Your Google User Data: Notwithstanding anything else in this Privacy Policy, if you provide the App access to the following types of your Google data, the App's use of that data will be subject to these additional restrictions:
				</Heading>


				<UnorderedList>
					<ListItem>The App will only use access to read, write, modify, or control Gmail message bodies (including attachments), metadata, headers, and settings to provide a web email client that allows users to compose, send, read, and process emails and will not transfer this Gmail data to others unless doing so is necessary to provide and improve these features, comply with applicable law, or as part of a merger, acquisition, or sale of assets.</ListItem>
					<ListItem>The App will not use this Gmail data for serving advertisements.</ListItem>
					<ListItem>The App will not allow humans to read this data unless we have your affirmative agreement for specific messages, doing so is necessary for security purposes such as investigating abuse, to comply with applicable law, or for the App's internal operations and even then only when the data have been aggregated and anonymized.</ListItem>
				</UnorderedList>
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				4. How we share your Personal Information
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				We may disclose the Personal Information that we collect about you as described below or otherwise disclosed to you at the time the data is collected.
			</Text>
			<Text>
				<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				4.1. Personal information, including E-mail Data
				</Heading>
				<b>Trusted Service Providers.</b> We engage certain trusted third parties to perform functions and provide services to us, including hosting and maintenance, error monitoring, debugging, performance monitoring, billing, customer relationship management, database storage and management, and direct marketing campaigns. We may share your Personal Information with these third parties, but only to the extent necessary to perform these functions and provide such services. We also require these third parties to maintain the privacy and security of the Personal Information they process on our behalf.
				<br />
				<b>Compliance with Laws and Law Enforcement.</b> AutoInbox cooperates with government and law enforcement officials or private parties to enforce and comply with the law. To the extent permitted under applicable law, we may disclose any information about you to government or law enforcement officials or private parties as we believe is necessary or appropriate to investigate, respond to, and defend against claims, for legal process (including subpoenas), to protect the property and rights of AutoInbox or a third party, to protect AutoInbox against liability, for the safety of the public or any person, to prevent or stop any illegal, unethical, fraudulent, abusive, or legally actionable activity, to protect the security or integrity of the Service and any equipment used to make the Service available, or to comply with the law.
				<br/>
				<b>Business Transfers.</b> AutoInbox may sell, transfer or otherwise share some or all of its assets, including Personal Information, in connection with a merger, acquisition, reorganization, sale of assets, or similar transaction, or in the event of insolvency or bankruptcy. You will have the opportunity to opt out of any such transfer if the new entity’s planned processing of your information differs materially from that set forth in this Privacy Policy.
				<Heading size={"sm"} mt={"15px"} mb={"15px"}>
					4.2. Personal information, excluding E-mail Data
				</Heading>
				<b>Other Third Parties.</b> We may share Personal Information with our headquarters and affiliates, and business partners to whom it is reasonably necessary or desirable for us to disclose your data for the purposes described in this Privacy Policy. We may also make certain non-Personal Information available to third parties for various purposes, including for business or marketing purposes or to assist third parties in understanding our users’ interest, habits, and usage patterns for certain programs, content, services, advertisements, promotions, and functionality available through the Service.
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				5. How we protect your Personal Information
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				AutoInbox is very concerned about safeguarding the confidentiality of your Personal Information. We employ administrative and electronic measures designed to appropriately protect your Personal Information against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing of the Personal Information in our possession. Please be aware that no security measures are perfect or impenetrable. We cannot guarantee that information about you will not be accessed, viewed, disclosed, altered, or destroyed by breach of any of our administrative, physical, and electronic safeguards, subject to requirements under applicable law to ensure or warrant information security.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				We will make any legally-required disclosures of any known breach of the security, confidentiality, or integrity of your unencrypted electronically stored Personal Information to you via email or conspicuous posting on our Site in the most expedient time possible and without unreasonable delay, consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system , and any other disclosures that may be required under applicable law.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				We also take measures to delete your Personal Information or keep it in a form that does not permit identifying you when this information is no longer necessary for the purposes for which we process it, unless we are required by law to keep this information for a longer period. When determining the retention period, we take into account various criteria, such as the type of products and services requested by or provided to you, the nature and length of our relationship with you, possible re-enrollment with our products or services, the impact on the services we provide to you if we delete some information from or about you, mandatory retention periods provided by law and the statute of limitations.
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				6. Your Rights and Choices
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				If you decide at any time that you no longer wish to receive such marketing communications from us, please follow the unsubscribe instructions provided in any of the communications. You may also opt out from receiving commercial email from us by sending your request to us by email at privacy@autoinbox.ai. Please be aware that, even after you opt out from receiving commercial messages from us, you will continue to receive administrative messages from us regarding the Service.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				You have the right to request that personal information be deleted, and you are consenting to the qualifications to that right, as described in Section 2. In addition, you have the right to request access and receive information about the Personal Information we maintain about you. You are agreeing to waive the right to data portability (to transfer your Personal Information to another company), and any other rights not explicitly included within this Privacy Policy.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				Where required by law, we obtain your consent for the processing of certain Personal Information collected by cookies or similar technologies, or used to send you direct marketing communications, or when we carry out other processing activities for which consent may be required. If we rely on consent for the processing of your Personal Information, you have the right to withdraw it at any time and free of charge. When you do so, this will not affect the lawfulness of the processing before your consent withdrawal.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				To update your preferences, ask us to remove your information from our mailing lists, ask us to delete your account or submit a request to exercise your rights under applicable law, please contact us as specified in the “How to Contact Us” section below.
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				7. Data Transfers
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				AutoInbox is based in the United States. Personal Information that we collect may be transferred to, and stored at, any of our affiliates, partners or service providers which may be inside or outside the European Economic Area (“EEA”) and Switzerland, including the United States. By submitting your personal data, you agree to such transfers. Your Personal Information may be transferred to countries that do not have the same data protection laws as the country in which you initially provided the information. When we transfer or disclose your Personal Information to other countries, we will protect that information as described in this Privacy Policy.
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				8. Children's Privacy
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				The Site is not directed to persons under 18. If a parent or guardian becomes aware that his or her child has provided us with Personal Information without their consent, he or she should contact us at privacy@autoinbox.ai. We do not knowingly collect Personal Information from children under 18. If we become aware that a child under 18 has provided us with Personal Information, we will delete such information from our files.
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				9. Updates to this Privacy Policy
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				This Privacy Policy may be updated from time to time for any reason; each version will apply to information collected while it was in place. We will notify you of any modifications to our Privacy Policy by posting the new Privacy Policy on our Site at <a href={"www.autoinbox.ai"}>www.autoinbox.ai</a> and indicating the date of the latest revision. You are advised to consult this Privacy Policy regularly for any changes.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				In the event that the modifications materially alter your rights or obligations hereunder, we will make reasonable efforts to notify you of the change. For example, we may send a message to your email address or generate a pop-up or similar notification when you access the Service for the first time after such material changes are made. Your continued use of the Service after the revised Privacy Policy has become effective indicates that you have read, understood and agreed to the current version of this Privacy Policy.
			</Text>
			<Divider mt={"15px"} mb={"15px"}/>

			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				10. CALIFORNIA USER RIGHTS
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				This section applies to you if you are a resident of California and a consumer with rights under the California Consumer Privacy Act (CCPA) or have similar rights by law in your jurisdiction. Some CCPA rights may not be applicable to you when personal information is processed in connection with your employment or business dealings.
			</Text>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				1. Right to Know About Personal Information Collected, Disclosed or Sold
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				You have the right to request that we disclose certain information to you about our collection, use, disclosure or sale of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request (see Exercising Access, Deletion and Opt-out Rights), and subject to certain limitations that we describe below, we will disclose such information to you.
			</Text>
			<Text fontSize={"lg"} mt={"15px"}>
				You have the right to request any or all of the following:
				<UnorderedList>
					<ListItem>The categories of personal information we collected about you.</ListItem>
					<ListItem>The categories of sources from which the personal information is collected.</ListItem>
					<ListItem>Our business or commercial purpose for collecting or selling that personal information.</ListItem>
					<ListItem>The categories of third parties with whom we share that personal information.</ListItem>
					<ListItem>The specific pieces of personal information we collected about you.</ListItem>
				</UnorderedList>
			</Text>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				2. Right to Request Deletion			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable individual request (see Exercising Access, Deletion and Opt-out Rights), we will delete (and direct our service providers to delete) your personal information from our records. However, we may retain personal information that has been de-identified or aggregated.  Furthermore, we may deny your deletion request if retaining the information is necessary for us or our service provider(s) to perform certain actions, such as detecting security incidents and protecting us from illegal activity.
			</Text>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				3. Sale of Personal Information
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				We do 'sell' personal information, as that term sell is defined under the CCPA, that is contained within our searchable databases.  We do not “sell” your account registration information on our Service.
			</Text>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				4. Exercising Access, Deletion and Opt-out Rights
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				To exercise the access and deletion rights described above, please submit a request to us by either:
				<br/>
				<UnorderedList>
					<ListItem>Emailing us at privacy@autoinbox.ai OR</ListItem>
					<ListItem>Filling out our online form</ListItem>
				</UnorderedList>
				<br/>The request should include your contact information and describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. In addition, you should provide sufficient information that allows us to reasonably verify that you are the person about whom we collected the personal information. Upon receiving your request, we may reach out to seek further verification from you. If you use an authorized agent to make request, you must provide the authorized agent written permission to do so, and we may require that you verify your identity directly with us. In order to protect the security of your personal information, we will not honor a request if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. The method and information used to verify your identity will vary depending on the nature of the request, and may include your email address, government identification and/or signed affidavit. You have the right not to receive discriminatory treatment for exercising any of your privacy rights.
			</Text>
			<Heading size={"md"} mt={"15px"} mb={"15px"}>
				11. How to Contact Us
			</Heading>
			<Text fontSize={"lg"} mt={"15px"}>
				AutoInbox, owned and operated by Adventurous AI LLC, is the entity responsible for the processing of your Personal Information. If you have any questions or comments regarding this Privacy Policy, or if you would like to exercise your rights to your Personal Information, you may contact us by emailing us at privacy@autoinbox.ai or by writing to us at:
				<br/><br/>
				Adventurous AI LLC c/o AutoInbox
				<br/>30 N Gould St
				<br/>Ste R
				<br/>Sheridan, WY 82801 USA

				<br/><br/>This Privacy Policy is effective as of the Last Updated date found at the beginning of this policy.
			</Text>
		</Box>
	)
}

function PrivacyPolicy() {

	return (
		<Container py={{ base: '16', md: '24' }}>
			<ScrollToTop />
			<Stack spacing="10" shouldWrapChildren>
				<Header />
				<ActualContent />
			</Stack>
		</Container>
);
}

export default PrivacyPolicy;
