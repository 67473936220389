import Home from "./home";
import TermsOfService from "./terms-of-service";
import DataDisclosure from "./data-disclosure";
import PrivacyPolicy from "./privacy-policy";
import HowItWorks from "./how-it-works";

const staticRoutesList = [
	{
		path: "",
		element: <Home />,
		title: "home"
	},
	{
		path: "terms-of-service",
		element: <TermsOfService />,
		title: "terms-of-service"
	},
	{
		path: "data-disclosure",
		element: <DataDisclosure />,
		title: "data-disclosure"
	},
	{
		path: "privacy-policy",
		element: <PrivacyPolicy />,
		title: "privacy-policy"
	},
	{
		path: "how-it-works",
		element: <HowItWorks />,
		title: "how-it-works"
	}

];

export default staticRoutesList;