import {AiGuideChatbox} from "../components/ai-guide-chatbox";
import {Box, Button, Center, Spinner, Text, useBreakpointValue} from "@chakra-ui/react";
import {EmailList} from "../components/email-list";
import {
	useApplySortActionToOnboardingEmailsMutation,
	useGetUserOnboardingEmailsQuery
} from "../../../services/autoInboxApi"
import {InView} from "react-intersection-observer";
import {useEffect, useState} from "react";
import {NavLink as RouterNavLink} from 'react-router-dom';

const chatText = () => {
	return <Text align={"left"} fontSize={'md'}>
		Hello!
		<br></br>
		<br></br>
		I'm going to look at your e-mails, categorize them, and recommend several actions on what to do.
	</Text>
}

const chatText2 = () => {
	return <>
		<Text align={"left"} fontSize={'md'}>
			Alright, I categorized your emails and have some suggestions.
		</Text>
		<EmailList />
	</>
}

function ChatText3({data, EmailsAreLoading}) {
	const [applySortActionToOnboardingEmails, {isLoading, isSuccess}] = useApplySortActionToOnboardingEmailsMutation();

	const handleUpdate = async () => {
		const updatedData = { /* Your updated data */ };
		const additionalParam = "exampleParam";
		// Pass additional parameters along with updatedData
		await applySortActionToOnboardingEmails({ updatedData, additionalParam });
	};

	if (EmailsAreLoading) {
		return (<Center>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='xl'
			/>
		</Center>)
	}

	function NothingForMeToDo() {
		return (
			<>
				<Text align={"left"} fontSize={'md'}>
					Looks like there's nothing for me to do for now. You can head over to your inbox.
				</Text>
				<Button as={RouterNavLink} to={'/user-dashboard/inbox/active/all'} variant="primary" mt={"20px"}>
					Go to Inbox
				</Button>
			</>
		)
	}

	if (data) {
		const dataToUse = data.data.filter(email => !email.attributes.first_sort_action_applied);
		const actionsPresent = dataToUse.filter((email) => email.attributes.recommendation !== 'ai_not_finished')

		if (actionsPresent.length > 0) {
			return (<>
				<Text align={"left"} fontSize={'md'}>
					Alright, should I implement these changes to the {actionsPresent.length} emails?
				</Text>
				<Button variant="primary"  onClick={handleUpdate} disabled={isLoading || isSuccess} mt={"20px"}>
					{isLoading && 'Applying...'}
					{isSuccess && 'Applied'}
					{!isLoading && !isSuccess && 'Clean my inbox!'}
				</Button>
			</>)
		} else {return <NothingForMeToDo />}
	} else {return <NothingForMeToDo />}
}

const SecondChunkOfMessages = () => {
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	})
	const { data, isLoading: EmailsAreLoading, isError } = useGetUserOnboardingEmailsQuery();

	if (EmailsAreLoading) {
		return (<Center>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='xl'
			/>
		</Center>)
	}

	if (data && data.data.length > 0) {
		return(<>
			<AiGuideChatbox width={isDesktop ? "85vw": "100%"} chatText={chatText2()} />
			<AiGuideChatbox chatText={<ChatText3 data={data} EmailsAreLoading={EmailsAreLoading} />} />
		</>)
	} else if (data && data.data.length === 0) {
		return(<>
			<AiGuideChatbox chatText={
				<Text align={"left"} fontSize={'md'}>
					We're still importing your emails. Check back in a few minutes. Also, this service requires you to have at least one email in your inbox.
				</Text>
			} />
		</>)
	} else {
		return(<>
			<AiGuideChatbox chatText={
				<Text align={"left"} fontSize={'md'}>
					Looks like there was an issue. Please logout and log back in a few minutes. Contact support if you're having continued issues.
				</Text>
			} />
		</>)
	}

}

function AnalysisInProgress() {
	const [hasScrolled, setHasScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setHasScrolled(true);
		};


		window.addEventListener('wheel', handleScroll);

		// Cleanup listener on component unmount
		return () => {
			window.removeEventListener('wheel', handleScroll);
		};
	}, [setHasScrolled]);

	const onEndInViewChange = (inView, entry) => {
		if (!inView && !hasScrolled) {
			entry.target.scrollIntoView();
		}
	};

	return (
		<>
			<Box as="section"
			     minHeight="100vh"
			     px={"50px"}
			     // maxHeight="100vh" // container height
			     // overflowY="auto" // apply vertical scroll
			>
				<AiGuideChatbox chatText={chatText()} />
				<SecondChunkOfMessages />
				<InView as={Box} onChange={onEndInViewChange} />

			</Box>
			<Box minHeight={"5vh"}></Box>

		</>
);
}

export default AnalysisInProgress;
