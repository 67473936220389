import {Box, Image} from '@chakra-ui/react'
import image from "../images/long_logo.png";
import whiteImage from "../images/long_logo_white.png";


export const Logo = ({logoColor, ...props}) => {
	if (logoColor === 'white') {
		return <Box {...props} maxWidth={"250px"}>
			<Image src={whiteImage} alt="AutoInbox" objectFit="cover"/>
		</Box>
	} else {
		return <Box {...props} maxWidth={"250px"}>
			<Image src={image} alt="AutoInbox" objectFit="cover"/>
		</Box>
	}
}