import {useSelector} from "react-redux";
import {getUser, getUserEmail, getUserName, getUserPhoto} from "../../views/auth/authReducer";
import {Avatar, Button, HStack, Menu, MenuButton, MenuGroup, MenuList, Text} from "@chakra-ui/react";
import {LogoutButton} from "../LogoutButton";
import {Link} from "react-router-dom";

export const UserDesktopMenu = () => {
	const user = useSelector(getUser)
	const userPhoto = useSelector(getUserPhoto)
	const userEmail = useSelector(getUserName)
	const userName = useSelector(getUserEmail)

	if (user) {
		return(
			<HStack spacing="4">
				<Menu>
					<MenuButton>
						<HStack>
							<Text mr={"10px"}>
								{userName}
							</Text>
							<Avatar boxSize="10" name={userEmail} src={userPhoto} />
						</HStack>
					</MenuButton>
					<MenuList>
						<MenuGroup title='Settings'>
							<LogoutButton />
						</MenuGroup>
					</MenuList>
				</Menu>
			</HStack>
		)
	} else {
		return(
			<Link to={"/login-page"}>
				<Button>
					Sign In
				</Button>
			</Link>
		)
	}

}