import { BsStars } from 'react-icons/bs'
import { FaAccessibleIcon } from 'react-icons/fa'
import { IoRocketSharp } from 'react-icons/io5'
import { FiUserCheck, FiKey, FiAward} from 'react-icons/fi'

export const PrivacyFeatures = [
  {
    name: 'View our privacy policy',
    description:
      'View our full privacy policy, which also adheres to Google\'s Limited Use data policy.',
    icon: FiKey,
    link: '/privacy-policy'
  },
  {
    name: 'View our data disclosure',
    description: 'Learn about what data we access and why. Remember: We\'ll never sell or use your personal Google data for marketing or advertising, and use our access strictly to provide our service.',
    icon: FiUserCheck,
    link: '/data-disclosure'
  },
  {
    name: 'Learn about how it works',
    description:
      'Get a more detailed breakdown of how our product works and how it can simplify your inbox.',
    icon: FiAward,
    link: '/how-it-works'
  },
]