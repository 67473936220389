import React, {useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {TextEditor} from "./TextEditor";
import {Button, HStack, Spinner, Text} from "@chakra-ui/react";
import {useSendReplyToEmailMutation} from "../../../services/autoInboxApi";


export const ReplyDrafter = (props) => {
	const initialValue = "";
	const [value, setValue] = useState(initialValue);
	const {activeEmail, setOpenEditorValue, ...otherProps} = props
	const [sendReplyToEmail, {isLoading, isSuccess}] = useSendReplyToEmailMutation();

	const sendEmail = async () => {
		const replyData = {
			message_id: activeEmail.id,
			to: `${activeEmail.attributes.sender_name} <${activeEmail.attributes.sender_email}>`,
			draft_text: value
		};
		await sendReplyToEmail(replyData ).then((res) => {
			setOpenEditorValue(false)
		});
	};

	const saveDraft = async () => {
		const replyData = {
			message_id: activeEmail.id,
			to: `${activeEmail.attributes.sender_name} <${activeEmail.attributes.sender_email}>`,
			draft_text: value,
			keep_as_draft: true
		};
		await sendReplyToEmail(replyData ).then((res) => {
			setOpenEditorValue(false)
		});
	};

	const SendButton = () => {
		return(
			<Button variant="primary"  onClick={sendEmail} disabled={isLoading || isSuccess}>
				{(isLoading || isSuccess) && <Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='sm'
					mr={"10px"}
				/>}
				Send
			</Button>
		)
	}

	const SaveDraftButton = () => {
		return(
			<Button variant="secondary"  onClick={saveDraft} disabled={isLoading || isSuccess}>
				{(isLoading || isSuccess) && <Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='sm'
					mr={"10px"}
				/>}
				Save as draft
			</Button>
		)
	}

	return (
		<>
			<Text>
				Replying to <Text display={'inline'} fontWeight={"medium"}>{activeEmail.attributes.sender_name}</Text> <Text display={'inline'} opacity={0.6}>&lt;{activeEmail.attributes.sender_email}&gt;</Text>
			</Text>
			<TextEditor key={activeEmail.id} value={value} setValue={setValue} initialValue={initialValue}/>
			<HStack>
				{initialValue != value && <SendButton />}
				{initialValue != value && <SaveDraftButton />}
			</HStack>

		</>
	)
}