import {Outlet} from 'react-router-dom';
import {Box} from "@chakra-ui/react";

function DefaultLayout() {
	return (
		<Box as="section" overflowY="auto" bg="bg-surface" >
			<Outlet />
		</Box>
	)
}

export default DefaultLayout

