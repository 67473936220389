import {Avatar, Box, HStack, Text, useColorModeValue} from '@chakra-ui/react'
import HelperImage from '../../../../images/helper-avatar.png'

export const AiGuideChatbox = (props) => {
	const { chatText, ...rest } = props
	return (
		<Box as="section" mt="10" mb="10"

		>
			<HStack align={"start"}>
				<Box flex={"0"}>
					<Avatar
						borderWidth="6px"
						borderColor={useColorModeValue('white', 'gray.700')}
						size="lg"
						src={HelperImage}
						name='Jarvis'
					/>
				</Box>
				<Box>
					<Box bg="gray.100" rounded={"lg"} pt='3' pb='7' px='5' {...rest}>
						<Text color={'muted'} fontSize={'sm'} mb={'3'}>
							Eric, your AutoInbox AI
						</Text>
						{chatText}
					</Box>
				</Box>

			</HStack>
		</Box>
	)
}