import {
	Avatar,
	AvatarBadge,
	Box,
	Button,
	Center,
	Collapse,
	Divider,
	Heading,
	HStack,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react'
import {members} from './data'
import {useGetUserOnboardingEmailsQuery} from "../../../../services/autoInboxApi";
import {DesktopEmails} from "./DesktopEmails";
import {useState} from "react";

const MobileEmailList = () => {

	return (<Center
		mx="auto"
		py={{
			base: '4',
			md: '8',
		}}
		width="90%"

	>
		<Box py="4">
			<Stack>
				{members.map((member) => (
					<Box mb={'5px'} bg="bg-surface" py={"15px"}
					     shadow={{
						     sm: 'base',
					     }}
					     rounded={{
						     sm: 'lg',
					     }}
					     key={member.id}
					>
						<Stack fontSize="sm" px="4" spacing="4">
							<Stack direction="row" justify="space-between" spacing="4">
								<HStack spacing="3">
									<Avatar src={member.avatarUrl} boxSize="10">
										<AvatarBadge boxSize="4" bg={member.status === 'active' ? 'success' : 'subtle'} />
									</Avatar>
									<Box>
										<Text fontWeight="medium" color="emphasized">
											{member.name}
										</Text>
										<Text color="muted">{member.handle}</Text>
									</Box>
								</HStack>
								<Text color="muted">{member.lastSeen}</Text>
							</Stack>
							<Text
								color="muted"
								sx={{
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: '1',
									overflow: 'hidden',
									display: '-webkit-box',
								}}
							>
								Candy donut tart pudding macaroon. Soufflé carrot cake choc late cake biscuit jelly
								beans chupa chups dragée. Cupcake toffee gummies lemon drops halvah. Cookie fruitcake
								jelly beans gingerbread soufflé marshmallow.
							</Text>
						</Stack>
					</Box>
				))}
			</Stack>
		</Box>
	</Center>)
}

const EmailSection = ({heading, subtext, emails}) => {
	const [showContent, setShowContent] = useState(false);

	const handleToggleContent = () => setShowContent(!showContent);

	return (
		<>
			<Box >
				<Stack spacing="4" direction={{ base: 'column', md: 'row' }} m={"0"} justify="space-between">
					<Stack spacing="1" m={"0"}>
						<Heading size={{ base: 'xs' }} fontWeight="medium">
							{heading}
						</Heading>
						<Text color="muted">{subtext}</Text>
					</Stack>
					<Button onClick={handleToggleContent}>
						{showContent ? 'Hide Emails' : 'Show Emails'}
					</Button>
				</Stack>
			</Box>
			<Collapse in={showContent} animateOpacity>
				<DesktopEmails emails={emails} />
			</Collapse>

		</>
	)
}

const DesktopEmailList = () => {
	const [tabIndex, setTabIndex] = useState(0)


	const { data, isLoading, isError } = useGetUserOnboardingEmailsQuery();

	if ( data ) {
		const dataToUse = data.data.filter(email => !email.attributes.first_sort_action_applied);
		const keepInInbox = dataToUse.filter((email) => email.attributes.recommendation === 'keep_in_inbox')
		const eventuallyArchive = dataToUse.filter((email) => email.attributes.recommendation === 'eventually_archive_and_label' )
		const archive = dataToUse.filter((email) => email.attributes.recommendation === 'archive_and_label')
		const ai_not_finished = dataToUse.filter((email) => email.attributes.recommendation === 'ai_not_finished')

		return (<Box as="section" bg="bg-surface" width="100%" px="10" py="10" mt={"20px"}>
			<Stack spacing="4">
				{archive.length > 0 && <EmailSection heading={`Move ${archive.length} emails to storage`}
				              subtext={`You may want to read these emails later, but they're probably not urgent.`}
				              emails={archive} />}
				{archive.length > 0 && <Divider m={"0px"} p={"0px"} />}
				{eventuallyArchive.length > 0 &&
				<EmailSection heading={`Move ${eventuallyArchive.length} emails to storage in 3 days`}
				              subtext={`These emails should be reviewed, but I'll put them in a folder for you in a few days.`}
				              emails={eventuallyArchive} />}
				{eventuallyArchive.length && <Divider />}
				{keepInInbox.length > 0 && <EmailSection heading={`Keep ${keepInInbox.length} emails in the inbox`}
				              subtext={`These emails you should review before deciding what to do.`}
				              emails={keepInInbox} />}
				{ai_not_finished.length > 0 && <Text pt={(ai_not_finished.length < dataToUse.length) ? '50px' : '0px'}>I still need a little more time to review {ai_not_finished.length} other emails in your inbox. Check back later to see how many are left.</Text>}
			</Stack>
		</Box>)
	} else {
		return (<Center>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='xl'
			/>
		</Center>)
	}
}

export const EmailList = () => {
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	})

	return (
		<>
			<DesktopEmailList />
			{/*{isDesktop ? (<DesktopEmailList />) : (<MobileEmailList></MobileEmailList>) }*/}
		</>

	)
}