import {Outlet} from 'react-router-dom';

function UserDashboard() {

	return (
		<>
			<Outlet />
		</>
	);
}

export default UserDashboard
