import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {updateCredentialsAndCheck} from "./authReducer";
import {useDispatch} from "react-redux";
import {useGetUserProfileQuery} from "../../services/autoInboxApi";

const LoginWithRedirect = () => {
	const { value } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const { isSuccess, isError } = useGetUserProfileQuery()

	React.useEffect(() => {
		dispatch(updateCredentialsAndCheck({value: value}))
		if (isError) {
			navigate('/');
		}

		if (isSuccess) {
			navigate('/user-onboarding/analysis-in-progress');
		}
	}, [isSuccess, isError, navigate]);


	return (
		<React.Fragment />
	);
};

export default LoginWithRedirect;