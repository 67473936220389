import {
	Box,
	Container,
	Heading,
	Stack,
	Text,
	UnorderedList,
	ListItem
} from '@chakra-ui/react'
import React from "react";
import ScrollToTop from "../../../components/ScrollToTop";
import {ExternalLinkIcon} from "@chakra-ui/icons";

const Header = () => {
	return(
		<Box pt="8" pb="16">
			<Stack spacing={{ base: '8', md: '10' }} align="center">
				<Stack spacing={{ base: '4', md: '6' }} textAlign="center">
					<Stack spacing="4">
						<Heading size={{ base: 'm'}}>How It Works</Heading>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	)
}

const ActualContent = () => {
	return(
		<Box maxW="5xl" mx="auto">

			<Text fontsize={"m"} mt={"15px"}>
				Managing your inbox is a chore. Despite your best efforts to unsubscribe from all the marketing e-mails you get, you're still probably frustrated and overwhelmed with the size of your inbox.
				<br/><br/>
				Enter AutoInbox. Instead of trying to unsubscribe you to every newsletter and marketing list you're on, we help label, categorize, and archive your inbox for you. Instead of waiting to be removed from a list, we filter out your unnecessary e-mails for you immediately.
				<br/><br/>
				But we didn't stop there. We're changing the way you do e-mail by using AI - read on below to see how.
			</Text>
			<br/>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				Managing Your Inbox
			</Heading>
			<Text fontSize={"m"} mt={"15px"}>
				<b>1. Categorize e-mails.</b> First, AutoInbox parses your e-mail via AI and categorizes them into what type of e-mail they are: marketing, receipts of purchases, personal e-mails, and more.
				<br/><br/>
				<b>2. Sort e-mails.</b> Then, AutoInbox applies labels based on our AI-powered categories, and does one of three things:
				<UnorderedList ml={"35px"}>
					<ListItem><b>Priority e-mails:</b> Leave in your inbox, for you to read and respond to.</ListItem>
					<ListItem><b>Time-bound e-mails:</b> Leave in your inbox, and label+archive them after 7 days. These are things that are relevant now, but won't be in a short time period. You're probably too lazy to archive these or mark as read, so we'll do it for you.</ListItem>
					<ListItem><b>Low priority e-mails:</b> Label and archive. These e-mails won't be deleted, but will be moved from your inbox to a separate folder, so you can view them at your leisure. This keeps your inbox clean!</ListItem>
				</UnorderedList>
				{/*<br/>*/}
				{/*<b>3. Manage settings.</b> (COMING SOON) You can undo or change how we treat different e-mails by going to your settings page.*/}
			</Text>

			<br/>
			<Heading size={"sm"} mt={"15px"} mb={"15px"}>
				Your E-mail Digest
			</Heading>
			<Text>
				Every day, AutoInbox inserts a summary of e-mails we labeled and archived in your inbox, so you can view a summary of each e-mail in one place and decide if you want to undo the action.
				<br/><br/> You can also edit these e-mails in your inbox in our web app directly.
			</Text>

			{/*<br/>*/}
			{/*<Heading size={"sm"} mt={"15px"} mb={"15px"}>*/}
			{/*	Replying to E-mails*/}
			{/*</Heading>*/}
			{/*<Text>*/}
			{/*	AutoInbox also generates possible responses to e-mails that you want to reply to, which you can then use to create a draft and edit to your own preferences.*/}
			{/*</Text>*/}
		</Box>
	)
}

function HowItWorks() {

	return (
		<Container py={{ base: '16', md: '24' }}>
			<ScrollToTop />
			<Stack spacing="10" shouldWrapChildren>
				<Header />
				<ActualContent />
			</Stack>
		</Container>
);
}

export default HowItWorks;
