import {Route, Routes} from "react-router-dom";
import staticRoutesList from "./static-pages/static-routes-list";

import UserOnboarding from "./user-onboarding";
import userOnboardingRoutesList from "./user-onboarding/user-onboarding-routes-list";
import DefaultLayout from "./layouts/default-layout";
import LoginWithRedirect from "./auth/LoginWithRedirect";
import {useSelector} from "react-redux";
import {useGetUserProfileQuery} from "../services/autoInboxApi";
import React from "react";
import PrivateRoute from "./PrivateRoute";
import StaticPages from "./static-pages";
import UserDashboard from "./user-dashboard";
import Inbox from "./user-dashboard/inbox";
import {LoginPage} from "./static-pages/login-page";

const Router = () => {
	const userToken = useSelector(state => state.authReducer.userToken)
	const { isSuccess, isLoading } = useGetUserProfileQuery(undefined, {
		skip: !userToken, // Skip the query if userToken is not available
	});

	const staticRoutes = staticRoutesList.map(({ path, title, element }) => {
		return <Route key={title} path={`/${path}`} element={element} />;
	});

	const userOnboardingRoutes = userOnboardingRoutesList.map(({ path, title, element }, index) => {
		if (index === 0) {
			return <Route index key={title} element={element} />;
		} else {
			return <Route key={title} path={`${path}`} element={element} />;
		}
	});

	return <Routes>
		<Route element={<DefaultLayout />}>
			<Route path="/login-page" element={<LoginPage />} />

			<Route path="/login/:value" element={<LoginWithRedirect />} />
			<Route element={<StaticPages />}>
				{staticRoutes}
			</Route>
			<Route element={<PrivateRoute />}>
				<Route path="user-onboarding" element={<UserOnboarding />}>
					{userOnboardingRoutes}
				</Route>
				<Route path="user-dashboard" element={<UserDashboard />}>
					<Route path="inbox/:filter" element={<Inbox />}>
						<Route path=":subfilter" element={<Inbox />} />
					</Route>
				</Route>
			</Route>
		</Route>
	</Routes>
};

export default Router;