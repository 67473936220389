import { BsStars } from 'react-icons/bs'
import { FaAccessibleIcon } from 'react-icons/fa'
import { IoRocketSharp } from 'react-icons/io5'
import { FiMail } from 'react-icons/fi'
import {Link} from "@chakra-ui/react";

export const features = [
  {
    name: 'Clean your inbox with one click',
    description: 'AutoInbox uses AI to label your e-mails into pre-defined categories, saving you the hassle of doing it yourself. ' +
      'We highlight e-mails that are important or you need to reply to, and archive and label the rest.',
    icon: BsStars,
  },
  {
    name: 'Receive summarized daily digests of your inbox',
    description:
      'We\'ll monitor your inbox and take the appropriate action on every e-mail, then send you a daily digest so you can catch anything you missed. ' +
      'AI generated summaries help you skim your inbox and stay up to date.',
    icon: IoRocketSharp,
  },
  {
    name: 'Manage your inbox in a simplified web app',
    description:
      'You can treat AutoInbox as an assistant and continue using your favorite e-mail app, or use our inbox to view an organized version of your inbox. ' +
      'Write e-mails or save drafts without ever leaving AutoInbox.',
    icon: FiMail,
  },
]