import {updateCredentialsAndCheck} from "../views/auth/authReducer";
import {useDispatch} from "react-redux";
import {FiLogOut} from "react-icons/fi";
import {NavButton} from "./navbar/NavButton";
import {Button, HStack, Icon, MenuItem, Text} from "@chakra-ui/react";


export const LogoutButton = ({ buttonType, ...otherProps }) => {
	const dispatch = useDispatch()

	const logout = () => {
		dispatch(updateCredentialsAndCheck({value: null}))
	}
	if (buttonType === 'NavButton') {
		return(
			<Button variant="ghost"
			        width="100%"
			        justifyContent="start" {...otherProps}
			        onClick={logout}
			>
				<HStack spacing="3">
					<Icon as={FiLogOut} boxSize="6" color="subtle" />
					<Text>Logout</Text>
				</HStack>
			</Button>
		)
	} else {
		return(
			<MenuItem icon={<FiLogOut />} onClick={logout}>
				Logout
			</MenuItem>
		)
	}

}
