import {
	Box,
	Center,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	Spinner,
	useDisclosure,
} from '@chakra-ui/react'
import {useState} from 'react'
import {FiArrowLeft, FiMenu} from 'react-icons/fi'
import {ColumnHeader, ColumnHeading, ColumnIconButton} from './Column'
import {Main} from './Main'
import {InboxNavBar} from './InboxNavBar'
import {Sidebar} from './Sidebar'
import {useGetUserOnboardingEmailsQuery} from "../../../services/autoInboxApi";
import {useParams} from "react-router-dom";
import {titleize} from "../../../utils/stringHelpers";

function Inbox() {
	const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false)
	const [mainIsScrolled, setMainIsScrolled] = useState(false)
	const [activeEmail, setActiveEmail] = useState(false)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { filter, subfilter } = useParams();


	const { data, isLoading, isError } = useGetUserOnboardingEmailsQuery();

	if ( data ) {
		const dataToUse = data.data

		const keepInInbox = dataToUse.filter((email) => email.attributes.recommendation === 'keep_in_inbox' && email.attributes.category !== 'Uncategorized')
		const uncategorized = dataToUse.filter((email) => email.attributes.category === 'Uncategorized')
		const eventuallyArchive = dataToUse.filter((email) => email.attributes.recommendation === 'eventually_archive_and_label')
		const archive = dataToUse.filter((email) => email.attributes.recommendation === 'archive_and_label')

		let emailsToDisplay = keepInInbox

		if (filter === 'storage') {
			const archivedCategories = [...new Set(dataToUse.map(email => email.attributes.category))];

			const selectEmailsByCategory = (category) => {
				return archive.filter(email => email.attributes.category === category)
			}

			emailsToDisplay = selectEmailsByCategory(subfilter)
		} else if (filter === 'active') {
			switch (subfilter) {
				case 'moving_to_archive_soon':
					emailsToDisplay = eventuallyArchive
					break;
				case 'all':
					// Do nothing
					break;
				case 'needs_review':
					emailsToDisplay = keepInInbox
					break;
				case 'uncategorized':
					emailsToDisplay = uncategorized
					break;
				default:
					emailsToDisplay = keepInInbox
			}
		}

		return (
			<Flex height="100vh">
				<Box
					height="full"
					width={{
						md: '14rem',
						xl: '18rem',
					}}
					display={{
						base: 'none',
						lg: 'initial',
					}}
					overflowY="auto"
					borderRightWidth="1px"
				>
					<InboxNavBar dataToUse={dataToUse}/>
				</Box>
				<Box
					borderRightWidth="1px"
					width={{
						base: "full",
						md: '20rem',
						xl: '24rem',
					}}
					display={{
						base: activeEmail ? 'none' : 'initial',
						md: 'initial',
					}}
					overflowY="auto"
					onScroll={(x) => setSidebarIsScrolled(x.currentTarget.scrollTop > 32)}
				>
					<ColumnHeader shadow={sidebarIsScrolled ? 'base' : 'none'}>
						<HStack justify="space-between" width="full">
							<HStack spacing="3">
								<ColumnIconButton
									onClick={onOpen}
									aria-label="Open Navigation"
									icon={<FiMenu/>}
									display={{
										md: 'inline-flex',
										lg: 'none',
									}}
								/>
								<Drawer isOpen={isOpen} placement="left" onClose={onClose}>
									<DrawerOverlay/>
									<DrawerContent>
										<InboxNavBar  dataToUse={dataToUse} onClose={onClose}/>
									</DrawerContent>
								</Drawer>
								<ColumnHeading>{titleize(filter)} > {titleize(subfilter)}</ColumnHeading>
							</HStack>
							{/*<ColumnButton leftIcon={<FiRss/>}>Subscribe</ColumnButton>*/}
						</HStack>
					</ColumnHeader>
					<Sidebar setActiveEmail={setActiveEmail} activeEmail={activeEmail} emailsToDisplay={emailsToDisplay}/>
				</Box>
				{activeEmail && <Box
					flex="1"
					overflowY="auto"
					onScroll={(x) => setMainIsScrolled(x.currentTarget.scrollTop > 32)}
				>
					<ColumnHeader shadow={mainIsScrolled ? 'base' : 'none'}>
						<HStack justify="space-between" width="full">
							<HStack spacing="3">
								<ColumnIconButton
									aria-label="Navigate back"
									icon={<FiArrowLeft/>}
									display={{
										base: 'inline-flex',
										md: 'none',
									}}
									onClick={() => setActiveEmail(false)}
								/>
								{mainIsScrolled && <ColumnHeading>{activeEmail.attributes.subject}</ColumnHeading>}
							</HStack>
						</HStack>
					</ColumnHeader>
					<Main
						maxW="3xl"
						mx="auto"
						py="8"
						px={{
							base: '4',
							md: '8',
						}}
						setActiveEmail={setActiveEmail}
						activeEmail={activeEmail}
					/>
				</Box>}
			</Flex>
		);
	} else {
		return (<Center minHeight={'100vh'}>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='xl'
			/>
		</Center>)
	}
}

export default Inbox;
