import {Box, Button, CloseButton, Container, Input, LightMode, Stack, Text,} from '@chakra-ui/react'
import {useState} from "react";
import {useJoinWaitlistMutation} from "../services/autoInboxApi";

export const EmailWaitlist = () => {
	// Uncomment this to reset
	// localStorage.removeItem('email_waitlist')
	
	const existingEmail = localStorage.getItem('email_waitlist');
	const [value, setValue] = useState("");
	const [isInvalid, setIsInvalid] = useState(true);
	const [joinWaitlist, {isLoading, isSuccess, isError}] = useJoinWaitlistMutation();

	const handleChange = (e) => {
		setValue(e.target.value);
		setIsInvalid(!e.target.checkValidity());
	};


	const submitEmail = (e) => {
		joinWaitlist({email: value}).then((res) => {
			if (res.data) {
				localStorage.setItem('email_waitlist', value);
				window.location.reload();
			}
		})
	}


	if (existingEmail) {
		return (<>
		</>)
	} else {
		return(
			<Box bg="bg-accent" color="on-accent">
				<Container py={{ base: '4', md: '2.5' }} maxW="100%" position="relative">
					<CloseButton display={{ md: 'none' }} position="absolute" right="2" top="2" />
					<Stack
						direction={{ base: 'column', md: 'row' }}
						justify="space-between"
						spacing={{ base: '3', md: '8' }}
					>
						<Stack
							spacing="4"
							direction={{ base: 'column', md: 'row' }}
							align={{ base: 'start', md: 'center' }}
						>
							<Stack spacing="0.5" pe={{ base: '4', sm: '0' }}>
								<Text fontWeight="medium">AutoInbox is currently pending Google verification.</Text>
								<Text color="on-accent-muted">
									Enter your e-mail below to join our waitlist.
								</Text>
							</Stack>
						</Stack>
						<Stack
							as="form"
							onSubmit={(e) => e.preventDefault()}
							direction={{ base: 'column', sm: 'row' }}
							spacing={{ base: '3', md: '2' }}
							align={{ base: 'stretch', md: 'center' }}
						>
							<LightMode>
								{!isSuccess &&
									<Input
										placeholder="Enter your email"
										type="email"
										isRequired
										value={value}
										onChange={handleChange}
										variant="outline-on-accent"
									/>
								}

							</LightMode>
							<Button variant="primary-on-accent" flexShrink={0} type="submit"
							        isDisabled={isInvalid}
							        onClick={submitEmail}
							>
								{isLoading && 'Sending...'}
								{isSuccess && 'Sent'}
								{isError && 'Error'}
								{!isLoading && !isSuccess && !isError && 'Submit'}
							</Button>
						</Stack>
					</Stack>
				</Container>
			</Box>
		)
	}


}