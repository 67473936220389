import {combineReducers} from "redux";
import userSetupReducer from "./userSetupReducer";
import authReducer from "../../views/auth/authReducer";
import {autoInboxApi} from "../../services/autoInboxApi";

const rootReducer = combineReducers({
	userSetupReducer: userSetupReducer,
	authReducer: authReducer,
	[autoInboxApi.reducerPath]: autoInboxApi.reducer,
});

export default rootReducer;