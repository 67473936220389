import {Outlet} from 'react-router-dom';
import {Footer} from "../../components/footer";
import {Box} from "@chakra-ui/react";
import {Navbar} from "../../components/navbar";
import {EmailWaitlist} from "../../components/EmailWaitlist";

function StaticPages() {
	return (
		<>
			<Box as="section" minHeight="100vh" overflowY="auto">
				<EmailWaitlist />
				<Navbar />
				<Outlet />
				<Footer />
			</Box>
		</>
	);
}

export default StaticPages
