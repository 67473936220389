import { Box, Button, Container, Heading, Icon, Image, Square, Stack, Text, SimpleGrid } from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'
import { features } from './product-details-features'
import { PrivacyFeatures } from './product-details-privacy'
import {Link} from "react-router-dom";

export const ProductDetails = () => (
  <Box as="section" bg="bg.surface">
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <Stack
        spacing={{
          base: '12',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '4',
            md: '5',
          }}
          maxW="3xl"
        >
          <Stack spacing="3">
            <Heading
              size={{
                base: 'sm',
                md: 'md',
              }}
            >
              Why AutoInbox?
            </Heading>
          </Stack>
          <Text
            color="fg.muted"
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
          >
            If your inbox is overflowing and you don't know where to get started, AutoInbox is for you. See why:
          </Text>
        </Stack>
        <Stack
          direction={{
            base: 'column',
            md: 'row',
          }}
          spacing={{
            base: '12',
            lg: '16',
          }}
        >
          <Stack
            spacing={{
              base: '10',
              md: '12',
            }}
            maxW="xl"
            justify="center"
            width="full"
          >
            {features.map((feature) => (
              <Stack key={feature.name} spacing="4" direction="row">
                <Square
                  size={{
                    base: '10',
                    md: '12',
                  }}
                  bg="accent"
                  color="fg.inverted"
                  borderRadius="lg"
                >

                    <Icon
                    as={feature.icon}
                    boxSize={{
                      base: '5',
                      md: '6',
                    }}
                  />
                </Square>
                <Stack
                  spacing={{
                    base: '4',
                    md: '5',
                  }}
                  pt={{
                    base: '1.5',
                    md: '2.5',
                  }}
                >
                  <Stack
                    spacing={{
                      base: '1',
                      md: '2',
                    }}
                  >
                      <Text
                        fontSize={{
                          base: 'lg',
                          md: 'xl',
                        }}
                        fontWeight="medium"
                      >
                        {feature.name}
                      </Text>
                    <Text color="fg.muted">{feature.description}</Text>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Box width="full" overflow="hidden">
            <Image
              maxW="100%"
              minH={{
                base: '100%',
                lg: '560px',
              }}
              objectFit="cover"
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
              alt="Developer"
            />
          </Box>
        </Stack>
      </Stack>
    </Container>
  </Box>
)

export const ProductDetailsPrivacy = () => (
  <Box as="section" bg="bg.surface">
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <Stack
        spacing={{
          base: '12',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '4',
            md: '5',
          }}
          align="center"
          textAlign="center"
        >
          <Stack spacing="3">
            <Heading
              size={{
                base: 'sm',
                md: 'md',
              }}
            >
              Data & Privacy
            </Heading>
          </Stack>
          <Text
            color="fg.muted"
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            maxW="3xl"
          >
            Curious about how we use your data? Read on below to learn more.
          </Text>
        </Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3,
          }}
          columnGap={8}
          rowGap={{
            base: 10,
            md: 16,
          }}
        >
          {PrivacyFeatures.map((feature) => (
            <Stack
              key={feature.name}
              spacing={{
                base: '4',
                md: '5',
              }}
              align="center"
              textAlign="center"
            >
              <Square
                size={{
                  base: '10',
                  md: '12',
                }}
                bg="accent"
                color="fg.inverted"
                borderRadius="lg"
              >
                <Icon
                  as={feature.icon}
                  boxSize={{
                    base: '5',
                    md: '6',
                  }}
                />
              </Square>
              <Stack
                spacing={{
                  base: '1',
                  md: '2',
                }}
              >
                <Link to={feature.link}>
                  <Text
                    fontSize={{
                      base: 'lg',
                      md: 'xl',
                    }}
                    fontWeight="medium"
                  >
                    {feature.name}
                  </Text>
                </Link>
                <Text color="fg.muted">{feature.description}</Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
)