import {Button, HStack, Icon, Text} from '@chakra-ui/react'
import {NavLink as RouterNavLink, useLocation} from "react-router-dom";

export const NavButton = (props) => {
	const { to, exact, icon, label, ...buttonProps } = props
	const location = useLocation();
	return(
		<RouterNavLink to={to} exact={exact}>
			{(props) => {
				const { isActive } = props;
				const selectedValue = isActive
				const pathIncludes = exact ? false : location.pathname.toString().includes(to)
				return (
					<Button variant="ghost"
					        width="100%"
					        justifyContent="start" {...buttonProps} aria-current={(selectedValue || pathIncludes) ? 'page' : undefined}>
						<HStack spacing="3">
							<Icon as={icon} boxSize="6" color="subtle" />
							<Text>{label}</Text>
						</HStack>
					</Button>
				);
			}}
		</RouterNavLink>
	)
}