import {Box, Button, Heading, Img, Stack, Text,} from '@chakra-ui/react'
import {Link} from "react-router-dom";

function Hero() {
	return (
		<Box>
			<Box as="section" py="7.5rem">
				<Box
					maxW={{
						base: '6xl',
						sm: '6xl',
					}}
					mx="auto"
					px={{
						base: '6',
						md: '8',
					}}
				>
					<Box textAlign="center">
						<Heading
							as="h1"
							size="xl"
							fontWeight="extrabold"
							maxW="48rem"
							mx="auto"
							lineHeight="1.2"
							letterSpacing="tight"
						>
							Inbox Zero. Zero Effort.
						</Heading>
						<Text fontSize="2xl" mt="4" maxW="3xl" mx="auto">
							Your AI assistant checks your emails and moves them to the right place.
							<br></br>
							It’s like magic.
						</Text>
					</Box>

					<Stack
						justify="center"
						direction={{
							base: 'row',
						}}
						mt="10"
						mb="20"
						spacing="4"
					>

						<Link to={"/login-page"}>
							<Button>
								Start the Inbox Magic
							</Button>
						</Link>


					</Stack>

					<Box
						className="group"
						cursor="pointer"
						position="relative"
						rounded="lg"
						overflow="hidden"
					>
						<Img
							alt="Screenshot of Envelope App"
							src="https://cdn.midjourney.com/d4d83020-66b4-4503-b7d5-cb644cd47060/0_3.png"
						/>
						{/*<Circle*/}
						{/*	size="20"*/}
						{/*	as="button"*/}
						{/*	bg="white"*/}
						{/*	shadow="lg"*/}
						{/*	color="pink.600"*/}
						{/*	position="absolute"*/}
						{/*	top="50%"*/}
						{/*	left="50%"*/}
						{/*	transform="translate3d(-50%, -50%, 0)"*/}
						{/*	fontSize="xl"*/}
						{/*	transition="all 0.2s"*/}
						{/*	_groupHover={{*/}
						{/*		transform: 'translate3d(-50%, -50%, 0) scale(1.05)',*/}
						{/*	}}*/}
						{/*>*/}
						{/*	<VisuallyHidden>Play demo video</VisuallyHidden>*/}
						{/*	<FaPlay />*/}
						{/*</Circle>*/}
					</Box>
				</Box>
			</Box>

		</Box>
);
}

export default Hero;
