export const members = [
	{
		id: '1',
		name: 'Christian Nwamba',
		handle: '@christian',
		avatarUrl: 'https://bit.ly/code-beast',
		status: 'active',
		message: 'Some message',
		lastSeen: 'just now',
	},
	{
		id: '2',
		name: 'Kent C. Dodds',
		handle: '@kent',
		avatarUrl: 'https://bit.ly/kent-c-dodds',
		status: 'active',
		message: 'Some message',
		lastSeen: '2hr ago',
	},
	{
		id: '3',
		name: 'Prosper Otemuyiwa',
		handle: '@prosper',
		avatarUrl: 'https://bit.ly/prosper-baba',
		status: 'active',
		message: 'Some message',
		lastSeen: '3hr ago',
	},
	{
		id: '4',
		name: 'Ryan Florence',
		handle: '@ryan',
		avatarUrl: 'https://bit.ly/ryan-florence',
		status: 'active',
		message: 'Some message',
		lastSeen: '4hr ago',
	},
	{
		id: '5',
		name: 'Segun Adebayo',
		handle: '@segun',
		avatarUrl: 'https://bit.ly/sage-adebayo',
		status: 'inactive',
		message: 'Some message',
		lastSeen: '5hr ago',
	},
	{
		id: '6',
		name: 'Phil Hartshorn',
		handle: '@phil',
		avatarUrl: 'https://bit.ly/phil-hartshorn',
		status: 'inactive',
		message: 'Some message',
		lastSeen: '6hr ago',
	},
	{
		id: '7',
		name: 'Olaoluwa Niyi-Awosusi',
		handle: '@niyi',
		avatarUrl: 'https://bit.ly/niyi-awosusi',
		status: 'inactive',
		message: 'Some message',
		lastSeen: '7hr ago',
	},
	{
		id: '8',
		name: 'Segun Adebayo',
		handle: '@segun',
		avatarUrl: 'https://bit.ly/sage-adebayo',
		status: 'inactive',
		message: 'Some message',
		lastSeen: '8hr ago',
	},
	{
		id: '9',
		name: 'Phil Hartshorn',
		handle: '@phil',
		avatarUrl: 'https://bit.ly/phil-hartshorn',
		status: 'inactive',
		message: 'Some message',
		lastSeen: '9hr ago',
	}
]